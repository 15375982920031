// PayPalButton.js
import React from 'react';
import { PayPalButtons } from '@paypal/react-paypal-js';

const PayPalButton = () => {
  return (
    <PayPalButtons
      style={{
        shape: 'rect',
        color: 'gold',
        layout: 'vertical',
        label: 'subscribe',
      }}
      createSubscription={(data, actions) => {
        return actions.subscription.create({
          plan_id: 'P-68S86830JX493724UMZNWVXY',
        });
      }}
      onApprove={(data, actions) => {
        alert(`Subscription ID: ${data.subscriptionID}`);
      }}
      onError={(err) => {
        console.error('PayPal Checkout onError', err);
      }}
    />
  );
};

export default PayPalButton;