import PayPalButton from "./PaypalButton";
import { useState } from "react";
import "./App.css"; // Import the CSS file
import emailjs from "@emailjs/browser";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

const initialState = {
  name: "",
  email: "",
  phone: "", // Add phone field
  message: "",
};

function App() {
  const [, setState] = useState(initialState);
  const [openDialog, setOpenDialog] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e.target, "e.target");
    emailjs
      .sendForm(
        "service_9xteq7f", // "YOUR_SERVICE_ID
        "template_yl9k69c", // "YOUR_TEMPLATE_ID
        e.target,
        "RbyUVrn2XpayXESAh" // React emailjs user ID
      )
      .then(
        (result) => {
          console.log(result.text);
          clearState();
          setOpenDialog(true);
        },
        (error) => {
          console.log(error.text);
          setOpenDialog(true);
        }
      );
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  return (
    <div className="App">
      <img
        src={process.env.PUBLIC_URL + "/automation.jpg"}
        alt="Automation"
        className="automation-image"
      />
      <h1 className="automation-text">Instagram Automation</h1>
      <p className="automation-text">
        Automate your Instagram account with our services
      </p>
      <p className="automation-text">
        Instagram Automation is a subscription-based service that helps you grow
        price: $60/month
      </p>
      <header className="App-header">
        <PayPalButton />
      </header>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
              </div>
              <form
                name="sentMessage"
                validate
                onSubmit={handleSubmit}
                className="form-container"
              >
                <div className="form-input">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="form-control"
                    placeholder="Name"
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="form-input">
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    className="form-control"
                    placeholder="Phone Number"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-input">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="form-input">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle
          style={{
            textAlign: "center",
            background: openDialog
              ? "linear-gradient(135deg, #75cafe, #4c90e2)"
              : "linear-gradient(135deg, #ffb3b3, #fd7979)",
          }}
        >
          {openDialog ? <h4>Message Sent</h4> : <h4>Try Again</h4>}
        </DialogTitle>
        <DialogContent>
          <h4>
            {openDialog
              ? "Your message has been sent successfully! We will be in contact with you shortly."
              : "There was an error. Please try again."}
          </h4>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default App;
